<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Добавить
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        Промокоды
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные компании    -->
        <div>
          <FormCardTitle class="data__text">Данные промокода</FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              rules="required"
              label="Количество"
              v-model="count"
              mask="XXX"
              validate-name="количество"
            ></ValidationInputField>

            <ValidationAutocompleteField
              rules="required"
              :search-function="citySearch"
              :disabled="!!companyId || !!kindergartenId || !!objectId"
              label="Город"
              v-if="[70].includes(currentRole)"
              v-model="city"
              validate-name="город"
            />
            <ValidationSelectField
              rules="required"
              v-model="by_access_type"
              :items="accessType"
              label="Тип доступа"
              validate-name="тип доступа"
            />

            <ValidationSelectField
              rules="required"
              v-model="by_object_type"
              :items="objectsType"
              label="Тип объекта"
              validate-name="тип объекта"
              :disabled="!by_access_type"
              v-if="by_access_type != 'card'"
            />
            <ValidationSelectField
              rules="required"
              v-model="by_object_type"
              :items="objectsTypeFromCard"
              label="Тип объекта"
              validate-name="тип объекта"
              v-else
            />
            <ValidationInputField
              rules="required||promocodePrecent"
              mask="###"
              label="Скидка %"
              v-model="discount_percent"
              validate-name="Процент скидки"
            ></ValidationInputField>
            <ValidationDateField
              rules="required||duractionDays"
              v-model="started"
              label="Дата начала активации"
              validate-name="дата начала активации"
            ></ValidationDateField>
            <ValidationSelectField
              :items="promocodePeriod"
              rules="required"
              label="Дней на активацию промокода"
              v-model="duration_days"
              validate-name="количество дней на активацию"
            />
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(createCoupns)"
          class="btn btn-green"
        >
          Создать
        </v-btn>
      </template>
    </CreateFormLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationDateField from "@/components/ValidationField/ValidationDateField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import { getCitiesTypesClearRequest } from "@/helpers/api/cities";
import router from "@/router";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "ControllerCreatePage",
  components: {
    ValidationSelectField,
    ValidationAutocompleteField,
    ValidationInputField,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout,
    MainLayout,
    ValidationDateField
  },
  props: {
    dealerId: {
      type: [String, Number]
    },
    companyId: {
      type: [String, Number]
    },
    objectId: {
      type: [String, Number]
    },
    kindergartenId: {
      type: [String, Number]
    }
  },
  data() {
    return {
      loading: false,
      promocodePeriod: [
        {
          value: "30",
          text: "30"
        },
        {
          value: "60",
          text: "60"
        },
        {
          value: "90",
          text: "90"
        }
      ],
      controllerType: [
        {
          value: "door",
          text: "Дверь"
        },
        {
          value: "wicket",
          text: "Калитка"
        },
        {
          value: "wing-gate",
          text: "Ворота распашные"
        },
        {
          value: "sliding-gate",
          text: "Ворота откатные"
        },
        {
          value: "lift-gate",
          text: "Шлагбаум"
        },
        {
          value: "porch-door",
          text: "Дверь подъездная"
        },
        {
          value: "inner-door",
          text: "Дверь внутренняя"
        },
        {
          value: "apartment-room",
          text: "Дверь квартирная"
        },
        {
          value: "lift",
          text: "Лифт"
        },
        {
          value: "mailbox",
          text: "Почтовый ящик"
        },
        {
          value: "garage-gate",
          text: "Гаражные ворота"
        },
        {
          value: "turnstile",
          text: "Турникет"
        }
      ],
      objectsType: [
        { text: "Автостоянка, парковка", value: "parking" },
        { text: "Банк", value: "bank" },
        { text: "Детский сад", value: "kindergarten" },
        { text: "Другое", value: "other" },
        { text: "Место проезда автотранспорта", value: "traffic-lane" },
        {
          text: "Многоквартирный дом, жилой комплекс",
          value: "housing-estate"
        },
        {
          text: "Образовательное учреждение",
          value: "educational-institution"
        },
        { text: "Общественное учреждение", value: "community-office" },
        { text: "Офис, бизнес-центр", value: "business-center" },
        { text: "Охраняемая зона", value: "protected-zone" },
        { text: "Промышленное предприятие", value: "industrial-enterprise" },
        { text: "Частный дом, коттедж", value: "apartment-house" }
      ],
      objectsTypeFromCard: [{ text: "Детский сад", value: "kindergarten" }],
      accessType: [
        { text: "Приложение", value: "app" },
        { text: "Карта", value: "card" }
      ],
      citySearch: async value => {
        return (
          await getCitiesTypesClearRequest({
            search: value,
            offset: null
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      }
    };
  },
  computed: {
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    duration_days: {
      get() {
        return this.$store.getters.getPromocodeCreateForm.duration_days;
      },
      set(newValue) {
        this.$store.commit("setPromocodeCreateForm", {
          fieldName: "duration_days",
          value: newValue
        });
      }
    }, //id города
    started: {
      get() {
        return this.$store.getters.getPromocodeCreateForm.started;
      },
      set(newValue) {
        this.$store.commit("setPromocodeCreateForm", {
          fieldName: "started",
          value: newValue
        });
      }
    }, //id города
    by_access_type: {
      get() {
        return this.$store.getters.getPromocodeCreateForm.by_access_type;
      },
      set(newValue) {
        this.$store.commit("setPromocodeCreateForm", {
          fieldName: "by_access_type",
          value: newValue
        });
      }
    }, //id города
    city: {
      get() {
        return this.$store.getters.getPromocodeCreateForm.by_city;
      },
      set(newValue) {
        this.$store.commit("setPromocodeCreateForm", {
          fieldName: "by_city",
          value: newValue
        });
      }
    }, //id города

    discount_percent: {
      get() {
        return this.$store.getters.getPromocodeCreateForm.discount_percent;
      },
      set(newValue) {
        this.$store.commit("setPromocodeCreateForm", {
          fieldName: "discount_percent",
          value: newValue
        });
      }
    }, //diller
    by_object_type: {
      get() {
        return this.$store.getters.getPromocodeCreateForm.by_object_type;
      },
      set(newValue) {
        this.$store.commit("setPromocodeCreateForm", {
          fieldName: "by_object_type",
          value: newValue
        });
      }
    }, //diller
    count: {
      get() {
        return this.$store.getters.getPromocodeCreateForm.count;
      },
      set(newValue) {
        this.$store.commit("setPromocodeCreateForm", {
          fieldName: "count",
          value: newValue
        });
      }
    }, //name,
    type: {
      get() {
        return this.$store.getters.getPromocodeCreateForm.type.value;
      },
      set(newValue) {
        this.$store.commit("setPromocodeCreateForm", {
          fieldName: "type",
          value: newValue
        });
      }
    } //name
  },
  created() {},
  beforeDestroy() {
    this.$store.commit("clearPromocodePage");
  },

  methods: {
    createCoupns() {
      this.$store
        .dispatch("createPromocodes")
        .then(() =>
          this.$store
            .dispatch(
              "successNotification",
              actionMessage.add(successMessageDictionary.promocode)
            )
            .finally(() => router.go(-1))
        );
    }
  }
};
</script>

<style scoped></style>
